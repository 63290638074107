import React from 'react';

const GPayAPayImage = () => {
  return (
    <svg
      width="247"
      height="68"
      viewBox="0 0 247 68"
      fill="none"
      transform="translate(-16)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4689 24.1444C29.1109 23.3414 29.5466 22.2633 29.4317 21.1615C28.4919 21.2082 27.3451 21.7815 26.6811 22.5851C26.085 23.2733 25.5573 24.3966 25.6949 25.4521C26.7498 25.5436 27.8038 24.9248 28.4689 24.1444Z"
        fill="black"
      />
      <path
        d="M29.4195 25.6582C27.8874 25.567 26.5848 26.5277 25.8532 26.5277C25.1212 26.5277 24.0008 25.7042 22.7891 25.7264C21.2119 25.7496 19.7485 26.6413 18.9481 28.0596C17.302 30.8969 18.5137 35.1056 20.1145 37.4164C20.8919 38.5596 21.8288 39.8184 23.0633 39.7732C24.2297 39.7274 24.6869 39.0179 26.1047 39.0179C27.5215 39.0179 27.9333 39.7732 29.168 39.7503C30.4484 39.7274 31.2489 38.6065 32.0263 37.4621C32.9181 36.1589 33.2832 34.9005 33.3061 34.8315C33.2832 34.8086 30.8371 33.8702 30.8144 31.0565C30.7914 28.7006 32.7349 27.58 32.8263 27.5105C31.7288 25.8872 30.014 25.7042 29.4195 25.6582Z"
        fill="black"
      />
      <path
        d="M42.7591 22.4699C46.089 22.4699 48.4077 24.7651 48.4077 28.1069C48.4077 31.4606 46.0413 33.7678 42.6756 33.7678H38.9889V39.6308H36.3252V22.4698H42.7591V22.4699ZM38.9889 31.5319H42.0453C44.3644 31.5319 45.6843 30.2834 45.6843 28.1188C45.6843 25.9545 44.3644 24.7176 42.0572 24.7176H38.9889V31.5319Z"
        fill="black"
      />
      <path
        d="M49.1035 36.075C49.1035 33.8867 50.7804 32.5429 53.7537 32.3764L57.1785 32.1743V31.2111C57.1785 29.8196 56.2389 28.9871 54.6694 28.9871C53.1825 28.9871 52.2549 29.7005 52.0292 30.8186H49.6031C49.7458 28.5588 51.6722 26.8939 54.7644 26.8939C57.797 26.8939 59.7353 28.4994 59.7353 31.0087V39.6308H57.2735V37.5734H57.2143C56.489 38.9649 54.9071 39.8448 53.266 39.8448C50.8161 39.8448 49.1035 38.3226 49.1035 36.075ZM57.1785 34.9453V33.9582L54.0983 34.1484C52.5641 34.2555 51.6961 34.9334 51.6961 36.0037C51.6961 37.0977 52.5999 37.8113 53.9794 37.8113C55.7751 37.8113 57.1785 36.5744 57.1785 34.9453Z"
        fill="black"
      />
      <path
        d="M62.0591 44.2332V42.1519C62.2491 42.1994 62.6771 42.1994 62.8914 42.1994C64.0805 42.1994 64.7228 41.7 65.1151 40.4157C65.1151 40.3918 65.3413 39.6546 65.3413 39.6426L60.8223 27.1198H63.6048L66.7685 37.2999H66.8158L69.9795 27.1198H72.6909L68.0049 40.2847C66.9351 43.3175 65.6982 44.2926 63.1056 44.2926C62.8914 44.2926 62.2491 44.2688 62.0591 44.2332Z"
        fill="black"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M127.066 33.1028V39.9382H124.931V23.065H130.595C131.961 23.038 133.288 23.5628 134.256 24.5316C136.193 26.3616 136.312 29.4429 134.495 31.4208C134.415 31.5016 134.335 31.5823 134.256 31.663C133.261 32.6184 132.041 33.1028 130.595 33.1028H127.066ZM127.066 25.1371V31.0306H130.648C131.444 31.0575 132.213 30.7346 132.757 30.156C133.871 28.9854 133.845 27.1016 132.691 25.9713C132.147 25.4331 131.417 25.1371 130.648 25.1371H127.066Z"
          fill="#3C4043"
        />
        <path
          d="M140.716 28.0166C142.295 28.0166 143.541 28.4472 144.457 29.2949C145.372 30.1426 145.823 31.3267 145.823 32.8202V39.9382H143.78V38.337H143.687C142.799 39.6556 141.631 40.3149 140.159 40.3149C138.912 40.3149 137.851 39.9382 137.015 39.1847C136.193 38.485 135.728 37.4489 135.755 36.359C135.755 35.1615 136.206 34.2196 137.095 33.5065C137.983 32.7933 139.177 32.4435 140.663 32.4435C141.936 32.4435 142.971 32.6857 143.793 33.1432V32.6453C143.793 31.9052 143.475 31.2056 142.918 30.7212C142.348 30.2099 141.618 29.9273 140.862 29.9273C139.668 29.9273 138.726 30.4386 138.037 31.4612L136.153 30.2637C137.161 28.7701 138.687 28.0166 140.716 28.0166ZM137.957 36.3994C137.957 36.9645 138.222 37.4893 138.66 37.8122C139.138 38.189 139.721 38.3908 140.318 38.3774C141.22 38.3774 142.082 38.0141 142.719 37.3682C143.422 36.6954 143.78 35.9015 143.78 34.9866C143.117 34.4483 142.188 34.1792 140.995 34.1927C140.132 34.1927 139.403 34.408 138.819 34.8251C138.249 35.2422 137.957 35.767 137.957 36.3994Z"
          fill="#3C4043"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.549 28.3933L150.412 45.0109H148.21L150.863 39.1981L146.181 28.3933H148.502L151.885 36.6819H151.924L155.227 28.3933H157.549Z"
          fill="#3C4043"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.431 31.6227C118.431 30.9634 118.378 30.3041 118.271 29.6582H109.265V33.3854H114.425C114.212 34.5829 113.523 35.6594 112.515 36.3321V38.7541H115.592C117.396 37.0722 118.431 34.5829 118.431 31.6227Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.265 41.0954C111.838 41.0954 114.013 40.2342 115.592 38.7541L112.515 36.3321C111.652 36.9242 110.551 37.2606 109.265 37.2606C106.771 37.2606 104.662 35.5517 103.906 33.2643H100.735V35.767C102.354 39.0367 105.657 41.0954 109.265 41.0954Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.906 33.2643C103.508 32.0667 103.508 30.7615 103.906 29.5506V27.0613H100.736C99.3694 29.7928 99.3694 33.0221 100.736 35.7535L103.906 33.2643Z"
          fill="#FBBC04"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.265 25.5543C110.631 25.5274 111.944 26.0521 112.926 27.0075L115.658 24.2356C113.921 22.5941 111.639 21.6925 109.265 21.7195C105.657 21.7195 102.354 23.7916 100.735 27.0613L103.906 29.564C104.662 27.2631 106.771 25.5543 109.265 25.5543Z"
          fill="#EA4335"
        />
      </g>
      <rect x="85" y="16" width="1" height="32" fill="black" />
      <defs>
        <clipPath id="clip0">
          <rect
            width="75"
            height="48"
            fill="white"
            transform="translate(90.9775 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GPayAPayImage;
