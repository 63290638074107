import propTypes from 'prop-types';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import handleViewport from 'react-in-viewport';
import MediaQuery from 'react-responsive';
import * as styles from './loanFeatures.module.css';
import GPayAPayImage from './GPayAPayImage';

export function Feature({ feature, index, noBorder }) {
  const iconSrc = feature.icon.file.url;
  const title = feature.title;
  const description =
    feature.childContentfulFeatureDescriptionTextNode.description;

  return (
    <div className={styles.featureContainer}>
      <div className={styles.cardMobile} key={index}>
        <img src={iconSrc} alt="icon" className={styles.iconMobile} />
        <div className={styles.featureHeaderContainer}>
          <h3 style={{ textAlign: 'center' }}>{title}</h3>
        </div>
      </div>
      <AnimateHeight duration={400} height="auto">
        <p>{description}</p>
      </AnimateHeight>
      {!noBorder && <div className={styles.borderContainer}></div>}
    </div>
  );
}
Feature.propTypes = {
  noBorder: propTypes.bool,
  feature: propTypes.object,
  index: propTypes.number,
  isWhite: propTypes.bool,
};

class LoanFeatures extends React.Component {
  render() {
    const {
      loanFeatures,
      isWhite,
      newStyle,
      hideTitle,
      pay,
      title,
    } = this.props;
    if (newStyle) {
      return (
        <div className={styles.featuresListWrapper}>
          <div className={styles.circleDivider} />
          <ul className={styles.featuresList}>
            {!hideTitle && (
              <li className={styles.featuresTitle}>
                <h2>{title || 'Fördelar med Rockerkortet'}</h2>
              </li>
            )}
            {loanFeatures && (
              <>
                {loanFeatures.map(f => (
                  <li className={styles.feature} key={f.title}>
                    <img src={f.icon.file.url} />
                    <h3>{f.title}</h3>
                    <p>
                      {f.childContentfulFeatureDescriptionTextNode.description}
                    </p>
                  </li>
                ))}
                {pay && (
                  <li className={styles.feature}>
                    <h2>
                      Anslut Rockerkortet till Apple Pay och Google Pay så
                      slipper du plånboken
                    </h2>
                    <GPayAPayImage />
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      );
    }
    return (
      <div
        style={this.props.style}
        className={isWhite ? styles.bgWhite : styles.bg}
      >
        <MediaQuery maxWidth={896}>
          <div className={`container ${styles.wrapperMobile}`}>
            {loanFeatures !== undefined &&
              loanFeatures.map((feature, index) => (
                <Feature
                  noBorder={index === loanFeatures.length - 1}
                  key={index}
                  feature={feature}
                  index={index}
                ></Feature>
              ))}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={897}>
          <div className={`container ${styles.wrapper}`}>
            {loanFeatures !== undefined &&
              loanFeatures.map((feature, index) => {
                const iconSrc = feature.icon.file.url;
                const title = feature.title;
                const description =
                  feature.childContentfulFeatureDescriptionTextNode.description;
                return (
                  <div className={styles.card} key={index}>
                    <img src={iconSrc} alt="icon" className={styles.icon} />
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                );
              })}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default handleViewport(
  LoanFeatures,
  { rootMargin: '-300px' },
  { disconnectOnLeave: true },
);

LoanFeatures.propTypes = {
  title: propTypes.string,
  loanFeatures: propTypes.arrayOf(propTypes.object),
  inViewport: propTypes.bool,
  enterCount: propTypes.number,
  isWhite: propTypes.bool,
  style: propTypes.object,
  newStyle: propTypes.bool,
  hideTitle: propTypes.bool,
  pay: propTypes.bool,
};
