import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './TransactionSummary.module.css';
import { formatMoneyText } from '@lib/utils';
import dayjs from 'dayjs';

function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
}

const TransactionSummary = ({
  name,
  amount,
  date,
  city,
  receiverFirstName,
  receiverLastName,
  receiverGivenName,
  amountOwed,
}) => {
  const receiversName = receiverGivenName
    ? receiverGivenName
    : receiverFirstName;
  return (
    <>
      <h3 className={styles.requestText}>
        {receiversName} {receiverLastName} vill dela ett köp med dig{' '}
      </h3>
      <div className={styles.containerWithBottomBorder}>
        <div className={styles.topRow}>
          <div>{truncate(name, 25)}</div>
          <div>{formatMoneyText(amount / 100)}</div>
        </div>
        <div className={[styles.bottomRow]}>
          <div>
            {(date || city) && (
              <div>
                {date && city
                  ? `${dayjs(date).format('D MMM')}, ${truncate(city, 12)}`
                  : date
                  ? dayjs(date).format('D MMM')
                  : city}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.amountOwed}>
        <div>Ditt belopp är: {formatMoneyText(amountOwed / 100)}</div>
      </div>
    </>
  );
};

TransactionSummary.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.number,
  date: PropTypes.string,
  city: PropTypes.string,
  receiverFirstName: PropTypes.string,
  receiverLastName: PropTypes.string,
  receiverGivenName: PropTypes.string,
  amountOwed: PropTypes.number,
};

export default TransactionSummary;
