// extracted by mini-css-extract-plugin
export var buttons = "PaymentRequest-module--buttons--fd593";
export var content = "PaymentRequest-module--content--34cd8";
export var customComponent = "PaymentRequest-module--customComponent--412f6";
export var customComponentMobile = "PaymentRequest-module--customComponentMobile--ca9ef";
export var downloadWithNumber = "PaymentRequest-module--downloadWithNumber--690fd";
export var headerDefaultBg = "PaymentRequest-module--headerDefaultBg--ef99a";
export var headerTextContainer = "PaymentRequest-module--headerTextContainer--e98a3";
export var homeHeader = "PaymentRequest-module--homeHeader--92222";
export var largeHeader = "PaymentRequest-module--largeHeader--ed300";
export var loanSliderContainer = "PaymentRequest-module--loanSliderContainer--0b959";
export var mainSection = "PaymentRequest-module--mainSection--3fa2e";
export var outlinedButton = "PaymentRequest-module--outlinedButton--e61c3";
export var smallHeader = "PaymentRequest-module--smallHeader--48432";
export var statusText = "PaymentRequest-module--statusText--822a2";
export var sticker = "PaymentRequest-module--sticker--0cd2e";
export var storeLinks = "PaymentRequest-module--storeLinks--76989";