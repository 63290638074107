import TransactionSummary from '@components/Kort-components/TransactionSummary';
import { useMedia } from '@lib/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { AppBadges } from '../HomeHeader';
import PrimaryButton from '../primaryButton';
import * as styles from './PaymentRequest.module.css';

const PaymentRequestHeader = ({ headerClassName, transactionId }) => {
  const [loading, setLoading] = useState(true);
  const [transactionInfo, setTransactionInfo] = useState({});
  const [error, setError] = useState(false);
  const isMobile = useMedia(['(max-width: 896px)'], [true], false);
  const isProduction =
    typeof window !== 'undefined' &&
    'https://rocker.com' === window.location.origin;

  useEffect(() => {
    fetchData(transactionId);
  }, []);

  const fetchData = async param => {
    try {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append(
        'Authorization',
        'Basic YXBpX3VzZXJfdGVzdDpZV1U0TUdJMVptRXRPRGczTlMwME16TXlMV0U1TnpjdFlUUmtZMkptWWpreE1XRms=',
      );
      const response = await fetch(
        isProduction
          ? `https://card.rockerapi.dev/card/payment-request/${param}`
          : `https://card-test.rockerapi.dev/card/payment-request/${param}`,
        {
          headers,
          withCredentials: true,
        },
      );
      const data = await response.json();
      setTransactionInfo(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log(error);
    }
  };

  const _onAccept = async param => {
    try {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append(
        'Authorization',
        'Basic YXBpX3VzZXJfdGVzdDpZV1U0TUdJMVptRXRPRGczTlMwME16TXlMV0U1TnpjdFlUUmtZMkptWWpreE1XRms=',
      );

      await fetch(
        isProduction
          ? `https://card.rockerapi.dev/card/payment-request/${param}`
          : `https://card-test.rockerapi.dev/card/payment-request/${param}`,
        {
          method: 'put',
          headers,
          withCredentials: true,
        },
      ).then(() =>
        isMobile
          ? (window.location = `swish://`)
          : window.open('https://swish.nu'),
      );
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const _onReject = async param => {
    try {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append(
        'Authorization',
        'Basic YXBpX3VzZXJfdGVzdDpZV1U0TUdJMVptRXRPRGczTlMwME16TXlMV0U1TnpjdFlUUmtZMkptWWpreE1XRms=',
      );
      await fetch(
        isProduction
          ? `https://card.rockerapi.dev/card/payment-request/${param}/reject`
          : `https://card-test.rockerapi.dev/card/payment-request/${param}/reject`,
        {
          method: 'post',
          headers,
          withCredentials: true,
        },
      ).then(() => fetchData(transactionId));
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'PENDING':
        return 'pågående.';
      case 'CANCELED':
        return 'avbruten.';
      case 'EXPIRED':
        return 'atgången.';
      case 'SETTLED':
        return 'klar.';
      case 'REJECTED':
        return 'avvisad.';
      case 'ENDED':
        return 'avslutad.';
      default:
        return 'pågående.';
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
          }}
          className="container"
        >
          <div>Laddar...</div>
        </div>
      );
    }
    if (!transactionId || error) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
          }}
          className="container"
        >
          <div className={styles.statusText}>Transaktionen saknas</div>
        </div>
      );
    }
    if (transactionInfo.status !== 'PENDING') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
          }}
          className="container"
        >
          <div className={styles.statusText}>
            <div>Transaktionen är {getStatus(transactionInfo.status)}</div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="container"
      >
        <div className={styles.content}>
          <div className={styles.mainSection}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: 600,
                margin: 'auto',
                marginBottom: 32,
              }}
            >
              <TransactionSummary
                name={transactionInfo.transactionInfo[0].merchant.name}
                amount={transactionInfo.transactionInfo[0].amount.value}
                date={transactionInfo.transactionInfo[0].timestamp}
                city={transactionInfo.transactionInfo[0].merchant.city}
                receiverGivenName={transactionInfo.receiverName.givenName}
                receiverFirstName={transactionInfo.receiverName.firstNames}
                receiverLastName={transactionInfo.receiverName.lastName}
                amountOwed={transactionInfo.amount.value}
              />
              <div className={styles.buttons}>
                <PrimaryButton onClick={() => _onAccept(transactionId)}>
                  Betala via Swish
                </PrimaryButton>
                <PrimaryButton
                  ghost
                  className={styles.outlinedButton}
                  onClick={() => _onReject(transactionId)}
                >
                  Tacka nej
                </PrimaryButton>
              </div>
            </div>
            {isMobile && <AppBadges />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.headerDefaultBg}>
      <MediaQuery maxWidth={896}>
        <div
          className={`${styles.largeHeader} ${headerClassName} ${styles.headerDefaultBg}`}
        >
          {renderContent()}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={897}>
        <div
          className={`${styles.smallHeader} ${headerClassName} ${styles.headerDefaultBg}`}
        >
          {renderContent()}
        </div>
      </MediaQuery>
    </div>
  );
};

PaymentRequestHeader.propTypes = {
  headerTitle: PropTypes.string,
  headerClassName: PropTypes.string,
  transactionId: PropTypes.string,
};

export default PaymentRequestHeader;
