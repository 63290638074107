import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import * as styles from './minimalHeader.module.css';

function MinimalHeader({ logo }) {
  return (
    <header className={styles.minimalHeader}>
      <Link to="/">
        <img src={logo} />
      </Link>
    </header>
  );
}

MinimalHeader.propTypes = {
  logo: PropTypes.string,
};

export default MinimalHeader;
