import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '../components/seo';

import LoanFeatures from '@components/lan-components/loanFeatures';
import MinimalHeader from '@src/components/minimalHeader';
import PaymentRequestHeader from '@components/Kort-components/PaymentRequest';

const PaymentRequest = ({ data, ...restProps }) => {
  const transactionId = restProps['*'];
  const cardFeatures = data.contentfulPage.pageContent[0].feature;
  const RockerLogo = data.contentfulPage.pageContent[1].icon.file.url;

  return (
    <>
      <SEO noIndex />
      <MinimalHeader logo={RockerLogo} />
      <main>
        <div>
          <PaymentRequestHeader transactionId={transactionId} />
          <LoanFeatures
            loanFeatures={cardFeatures}
            isWhite
            newStyle
            hideTitle
          />
        </div>
      </main>
    </>
  );
};

PaymentRequest.propTypes = {
  data: propTypes.object,
  location: propTypes.object,
};

export default PaymentRequest;

export const query = graphql`
  query PaymentRequestQueryquery {
    contentfulPage(pageUrl: { eq: "/payment-request" }) {
      pageHeader
      pageHeaderDescription {
        childMarkdownRemark {
          html
        }
      }
      pageContent {
        ... on ContentfulFeatures {
          feature {
            icon {
              file {
                url
              }
            }
            childContentfulFeatureDescriptionTextNode {
              description
            }
            title
          }
        }
        ... on ContentfulFeature {
          id
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
